import React, { useState, useContext } from 'react'
import NoteEditor from './NoteEditor'
import axios from 'axios'
import GlobalContext from './GlobalContext'
import spinnerImg from '../images/spinner.gif'
import { formatWithTime } from '../lib/date'
import './NoteEditorContainer.scss'
import usePersistedSetting from '../hooks/usePersistedSetting'
import { useDebounceCallback } from '@react-hook/debounce'

export default function NoteEditorContainer({ note, updateNote }) {
  const [title, setTitle] = useState(note && note.title)
  const [text, setText] = useState(note && note.text)
  const [savingMessage, setSavingMessage] = useState('')
  const [mode, setMode] = usePersistedSetting('editorMode', 'live')

  const { toast } = useContext(GlobalContext)

  function save(v) {
    const updatedNote = {}
    const fields = ['title', 'text']
    for (const field of fields) {
      if (v[field] !== note[field]) {
        updatedNote[field] = v[field]
      }
    }
    setSavingMessage(<>Saving <img alt="wait spinner indicator" src={spinnerImg} /></>)
    axios.patch('/api/notes/' + note.id, updatedNote)
      .then(response => {
        updateNote(response.data)
        setSavingMessage('Saved at ' + formatWithTime(new Date(response.data.updatedAt)))
      })
      .catch(error => {
        setSavingMessage('An error occurred saving your note. Please try again.')
        toast.error('An error occurred saving your note. Please try again.')
      })
  }

  function indicateWaiting() {
    setSavingMessage('Waiting for you to pause typing...')
  }

  const onChange = useDebounceCallback((name, value) => {
    save({
      title,
      text,
      [name]: value,
    })
  }, 2000)

  if (note) {
    return <div className="note-editor-container">
      <NoteEditor
        title={title}
        text={text}
        onChangeTitle={t => {
          setTitle(t)
          indicateWaiting()
          onChange('title', t)
        }} 
        onChangeText={t => {
          setText(t)
          indicateWaiting()
          onChange('text', t)
        }}
        savingMessage={savingMessage}
        mode={mode}
        setMode={setMode}
      />
    </div>
  }
  return <div>
    Please select a note on the left
  </div>
}
