export function format(date, options = {}) {
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export function formatWithTime(date) {
  return format(date, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
}
