import React from 'react'
import Highlight from './Highlight'
import './SearchResults.scss'
import LinkButton from './LinkButton'
import classnames from 'classnames'

export default function SearchResults({ onSelectNoteIdToEdit, noteIdToEdit, results }) {
  return <div className="search-results">
    {results.total
      ? <div>
          <div>{results.total} results</div>
          <div className="results owl">
            {results.hits.map(x => {
              // Reminder: not all results will have a highlight. For example if you search for a term using a minus sign.
              // There should be 0 or 1 title highlight.
              const title = x.highlight && x.highlight.title ? x.highlight.title[0] : x.source.title
              // There could be 0 or more text highlights.
              const highlightedText = x.highlight && x.highlight.text
                ? x.highlight.text.map((highlightText, i) => <pre key={i}>
                  <Highlight text={highlightText} /></pre>)
                : <pre><Highlight text={x.source.text} /></pre>

              return <div
                key={x.id}
                className={classnames("result", { selected: x.id === noteIdToEdit })}
                onClick={() => onSelectNoteIdToEdit(x.id)}
              >
                <div>
                  <div className="header"><LinkButton className="link" onClick={() => {
                    // Do nothing. It's handled by the outer div.
                  }}><Highlight text={title} /></LinkButton></div>
                  <div>{highlightedText}</div>
                </div>
              </div>
            })}
          </div>
        </div>
      : <div><em>Your search turned up no results</em></div>
    }
  </div>
}
