const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base', ignorePunctuation: true })

export const naturalSorter = (array, selector, options) => {
  let a = array.slice()
  if (selector) {
    a = a.sort((a, b) => collator.compare(selector(a), selector(b)))
  } else {
    a.sort((a, b) => collator.compare(a, b))
  }
  if (!selector) {
    options = selector
  }
  if (options && options.reverse) {
    a.reverse()
  }
  return a
}
