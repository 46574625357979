import React from 'react'
import './NoteEditor.scss'
import { useExpandHeight } from '../hooks/useExpandHeight'
import MDEditor from '@uiw/react-md-editor'
import * as commands from '@uiw/react-md-editor/lib/cjs/commands/index'
import extend from 'lodash/extend'

export default function NoteEditor({
  title,
  text,
  onChangeTitle,
  onChangeText,
  savingMessage,
  mode,
  setMode,
}) {
  const { el, height } = useExpandHeight()

  const customCommands = [commands.codeEdit, commands.codeLive, commands.codePreview].map(x => {
    const origExecute = x.execute
    const modifiedCommand = extend({}, x)
    modifiedCommand.execute = (...args) => {
      setMode(x.name)
      origExecute(...args)
    }
    return modifiedCommand
  })
  customCommands.push(commands.fullscreen)

  return <div className="note-editor">
    <div className="title">
      <input value={title} onChange={e => onChangeTitle(e.target.value)} placeholder="Enter title" />
    </div>
    <div className="meta">
      <div className="loading-indicator">
        {savingMessage}
      </div>
    </div>
    <div ref={el}>
      <MDEditor
        value={text}
        onChange={onChangeText}
        height={height}
        visiableDragbar={false}
        preview={mode}
        commands={customCommands}
      />
    </div>
  </div>
}
