import React from 'react'

export default function Loader(props) {
  const { isRequesting, error, isDone, children } = props

  if (isRequesting) {
    return <div>Loading...</div>
  } else if (error) {
    return <div>Error in Loader</div>
  } else if (isDone) {
    return children
  } else {
    // This is only show for the briefest moment during transitions with isRequesting.
    return <div></div>
  }
}
