import React from 'react'

export default function SortAndSearch({
  sortType,
  setSortType,
  sortDir,
  setSortDir,
}) {
  function handleSortType(type) {
    setSortType(type)
  }
  
  function handleSortDir(dir) {
    setSortDir(dir)
  }
  
  return <div>
    Sort by&nbsp;
    <select value={sortType} onChange={e => handleSortType(e.target.value)}>
      <option value={'updatedAt'}>Date</option>
      <option value={'title'}>Title</option>
      <option value={'viewedAt'}>Viewed</option>
    </select>
    <select value={sortDir} onChange={e => handleSortDir(e.target.value)}>
      <option value={'asc'}>Asc</option>
      <option value={'desc'}>Desc</option>
    </select>
  </div>
}
