import { useState, useEffect } from 'react'
import localforage from 'localforage'
import get from 'lodash/get'
import set from 'lodash/set'
import extend from 'lodash/extend'

export default function usePersistedSetting(settingName, defaultValue = null) {
  const [settings, setSettings] = useState({})
  // I'm not proud of this. What should be used for deps?
  const settingsJsonString = JSON.stringify(settings)
  useEffect(() => {
    (async () => {
      const persistedSettings = await localforage.getItem('settings')
      setSettings(persistedSettings)
    })()
  }, [settingsJsonString])

  function setter(val) {
    const newSettings = extend({}, settings)
    set(newSettings, settingName, val)
    setSettings(val)
    return localforage.setItem('settings', newSettings)
  }

  const setting = get(settings, settingName, defaultValue)
  return [setting, setter]
}
