import React, { useState, useRef, useEffect } from 'react'
import FuzzyHighlighter, { Highlighter } from 'react-fuzzy-highlighter'
import useKeyEvent from '../hooks/useKeyEvent'
import Mousetrap from 'mousetrap'
import classnames from 'classnames'
import './FuzzySearchContainer.scss'

// Having this here, as opposed to in state, means it's a global value and not a value
// per component. I'm ok with that for now since it's only used once.
// But it'd be better to figure out a way to use it per component.
let openSelectedNote = null

export default function FuzzySearchContainer({
  notes,
  selectNote,
  fuzzySearchTerm,
  setFuzzySearchTerm,
}) {
  const [show, setShow] = useState(false)
  const [selectedResultIndex, setSelectedResultIndex] = useState(0)
  const [numberOfResults, setNumberOfResults] = useState(0)
  const inputEl = useRef(null)
  useEffect(() => {
    if (show) {
      inputEl.current.focus()
    }
  }, [show])

  function localSelectNote(note) {
    setShow(false)
    selectNote(note)
  }

  function maybeDecrementSelectedResultIndex() {
    if (selectedResultIndex) {
      setSelectedResultIndex(selectedResultIndex - 1)
    }
  }

  function maybeIncrementSelectedResultIndex() {
    if (selectedResultIndex < numberOfResults - 1) {
      setSelectedResultIndex(selectedResultIndex + 1)
    }
  }
  function updateFuzzySearchTerm(val) {
    setSelectedResultIndex(0)
    setFuzzySearchTerm(val)
  }

  useKeyEvent('mod+b', () => setShow(!show))
  // This way esc only causes action when the user is focused in the input.
  const mousetrapOnInputEl = Mousetrap(inputEl.current)
  useEffect(() => {
    mousetrapOnInputEl.bind('esc', () => setShow(false))

    return () => mousetrapOnInputEl.unbind('esc')
  })
  useEffect(() => {
    mousetrapOnInputEl.bind('up', maybeDecrementSelectedResultIndex)

    return () => mousetrapOnInputEl.unbind('up')
  })
  useEffect(() => {
    mousetrapOnInputEl.bind('down', maybeIncrementSelectedResultIndex)

    return () => mousetrapOnInputEl.unbind('down')
  })
  useEffect(() => {
    mousetrapOnInputEl.bind('return', openSelectedNote)

    return () => mousetrapOnInputEl.unbind('return')
  })

  if (!show) {
    return <div>
      <button onClick={() => setShow(true)}>Find by title...</button>
    </div>
  }

  return <div className="fuzzy-search-container">
    <div>
      {/* Allow mousetrap binding even while input is in focus by setting class to mousetrap */}
      Search: <input className="mousetrap" placeholder="title..." type="text" ref={inputEl} value={fuzzySearchTerm} onChange={event => updateFuzzySearchTerm(event.target.value)} />
      <button onClick={() => setShow(false)}>Cancel</button>
    </div>
    <FuzzyHighlighter
      query={fuzzySearchTerm}
      data={notes}
      options={{
        shouldSort: true,
        includeMatches: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['title']
      }}
    >
      {({ results, formattedResults, timing }) => {
        const limitedResults = formattedResults.filter((v, i) => i < 5)
        setNumberOfResults(limitedResults.length)
        openSelectedNote = () => {
          const note = limitedResults[selectedResultIndex].item
          localSelectNote(note)
        }
        return (
          <ul>
            {limitedResults.map((formattedResult, resultIndex) => {
              if (formattedResult.formatted.title === undefined) {
                return null;
              }

              return (
                <li key={resultIndex} className={classnames({ selected: resultIndex === selectedResultIndex})}>
                  <button className="link" onClick={() => localSelectNote(formattedResult.item)}>
                    <Highlighter text={formattedResult.formatted.title}/>
                  </button>
                </li>
              );
            })}
          </ul>
        );
      }}
    </FuzzyHighlighter>
  </div>
}
