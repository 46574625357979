import React from 'react'
import Main from './Main'

export default function Dashboard({
  notes,
  setNotes,
  selectNote,
  selectedNote,
  updateNote,
  noteBeingLoaded,
  onAddNote,
  fuzzySearchTerm,
  setFuzzySearchTerm,
}) {
  return <div>
    <Main
      notes={notes}
      setNotes={setNotes}
      selectNote={selectNote}
      selectedNote={selectedNote}
      updateNote={updateNote}
      noteBeingLoaded={noteBeingLoaded}
      onAddNote={onAddNote}
      fuzzySearchTerm={fuzzySearchTerm}
      setFuzzySearchTerm={setFuzzySearchTerm}
    />
  </div>
}
