import React, { useEffect, useRef } from 'react'
import './SearchInputs.scss'

export default function SearchInputs({ searchTerms, onSetSearchTerms, onSearch }) {
  const inputEl = useRef(null)
  useEffect(() => {
    inputEl.current.focus()
  }, [])

  return <div className="search-inputs">
    Search terms: <input
      ref={inputEl}
      value={searchTerms}
      onChange={e => onSetSearchTerms(e.target.value)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onSearch()
        }
      }}
    />
    <button className="search" onClick={onSearch}>Search</button>
  </div>
}
