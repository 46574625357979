import React from 'react'
import NotesContainer from './NotesContainer'
import NoteEditorContainer from './NoteEditorContainer'
import './Main.scss'

export default function Main({
  notes,
  setNotes,
  selectNote,
  selectedNote,
  updateNote,
  noteBeingLoaded,
  onAddNote,
  fuzzySearchTerm,
  setFuzzySearchTerm,
}) {
  return <main>
    <NotesContainer
      notes={notes}
      setNotes={setNotes}
      selectNote={selectNote}
      selectedNote={selectedNote}
      noteBeingLoaded={noteBeingLoaded}
      onAddNote={onAddNote}
      fuzzySearchTerm={fuzzySearchTerm}
      setFuzzySearchTerm={setFuzzySearchTerm}
    />
    <NoteEditorContainer
      // Use a key because we use derived state in the NoteEditor.
      key={selectedNote && selectedNote.id}
      note={selectedNote}
      updateNote={updateNote}
    />
  </main>
}
