import React, { useContext } from 'react'
import GlobalContext from './GlobalContext'
import './Header.scss'
import { Link, NavLink } from 'react-router-dom'

export default function Dashboard() {
  const { user } = useContext(GlobalContext)
  
  return <div className="header-bar">
    <div className="title">CodeNotes</div>
    <nav className="howl">
      <NavLink activeClassName="active" exact to="/dashboard">Notes</NavLink>
      <NavLink activeClassName="active" to="/dashboard/search">Search</NavLink>
    </nav>
    <div className="user-info howl">
      <div>{user.profileName}</div>
      <Link to="/logout">Log out</Link>
    </div>
  </div>
}
