import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import GlobalContext from './GlobalContext'

export default function AddNoteContainer({ onAddNote }) {
  const [isAdding, setIsAdding] = useState(false)
  const [title, setTitle] = useState('')
  const { toast } = useContext(GlobalContext)
  const [isPosting, setIsPosting] = useState(false)
  const titleEl = useRef(null)

  useEffect(() => {
    if (isAdding) {
      titleEl.current.focus()
    }
  }, [isAdding])

  function save() {
    setIsPosting(true)
    axios.post('/api/notes', { title })
      .then(response => {
        setIsAdding(false)
        setTitle('')
        onAddNote(response.data)
      })
      .catch(error => {
        toast.error('An error occurred creating the note. Please try again.')
      })
      .then(() => setIsPosting(false))
  }

  const cancel = () => {
    setIsAdding(false)
    setTitle('')
  }

  return <div>
    {isAdding
      ? <div>
          Title <input
            ref={titleEl}
            value={title}
            onChange={e => setTitle(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                save()
              }
              if (e.key === 'Escape') {
                cancel()
              }
            }}
          />
          <button onClick={save} disabled={isPosting}>Save</button>
          <button onClick={cancel} disabled={isPosting}>Cancel</button>
        </div>
      : <button onClick={() => setIsAdding(true)}>+ Add note</button>
    }
  </div>
}
