import React from 'react'
import './Highlight.scss'

const startText = '<hi>'
const endText = '</hi>'

export default function Highlight({ text }) {
  const fragments = []

  // This for loop will break this string: 'this is <hi>my</hi> long <hi>wonderful message</hi> more words'
  // into this array: ['this is', 'my', 'long', 'wonderful message', 'more words']
  for (let i = 0; i < text.length; i++) {
    const startIndex = text.indexOf(startText, i)
    if (startIndex === -1) {
      fragments.push(text.substring(i))
      i = text.length
    } else {
      fragments.push(text.substring(i, startIndex))
      const endIndex = text.indexOf(endText, startIndex + startText.length)
      if (endIndex === -1) {
        throw new Error('Missing highlight end delimited')
      } else {
        fragments.push(text.substring(startIndex + startText.length, endIndex))
      }
      // Subtract 1 because the for loop will add it.
      i = endIndex + endText.length - 1
    }
  }

  return fragments.map((x, i) => {
    if (i % 2 === 1) {
      return <span key={i} className="highlight">{x}</span>
    }
    return x
  })
}
