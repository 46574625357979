import React, { useState, useContext } from 'react'
import LoaderP from './LoaderP'
import Dashboard from './Dashboard'
import axios from 'axios'
import useFetch from '../hooks/useFetch'
import update from 'immutability-helper'
import GlobalContext from './GlobalContext'

export default function DashboardContainer() {
  const [selectedNote, setSelectedNote] = useState()
  const [noteBeingLoaded, setNoteBeingLoaded] = useState()
  const [fuzzySearchTerm, setFuzzySearchTerm] = useState('')
  const { toast } = useContext(GlobalContext)
  function onSelectNote(note) {
    setNoteBeingLoaded(note)
    axios.get('/api/notes/' + note.id)
      .then(response => {
        setSelectedNote(response.data)
      })
      .catch(error => {
        toast.error('An error occurred trying to load that note. Try again.')
      })
      .then(() => {
        setNoteBeingLoaded(undefined)
      })
  }
  const notesState = useFetch('/api/notes')
  const { data: notes } = notesState
  
  function updateNote(note) {
    const updatedNotes = update(notes, {
      [notes.findIndex(x => x.id === note.id)]: { $set: note }
    })
    notesState.setData(updatedNotes)
  }

  function onAddNote(note) {
    notesState.setData([ note, ...notes ])
    onSelectNote(note)
  }

  return <LoaderP {...notesState}>
    <Dashboard
      notes={notes}
      setNotes={notesState.setData}
      selectNote={onSelectNote}
      selectedNote={selectedNote}
      updateNote={updateNote}
      noteBeingLoaded={noteBeingLoaded}
      onAddNote={onAddNote}
      fuzzySearchTerm={fuzzySearchTerm}
      setFuzzySearchTerm={setFuzzySearchTerm}
    />
  </LoaderP>
}
