import { useReducer, useEffect } from 'react'
import axios from 'axios'

export default function useFetch(url, count = 0) {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'start':
        return { ...state, isRequesting: true, error: null }
      case 'success':
        return { ...state, isRequesting: false, isDone: true, data: action.payload }
      case 'error':
        return { ...state, isRequesting: false, isDone: true, error: action.payload }
      case 'setData':
        return { ...state, data: action.payload }
      default:
        return state
    }
  }, {
    isRequesting: false,
    error: null,
    data: undefined,
    isDone: false,
  })

  useEffect(() => {
    if (url) {
      dispatch({ type: 'start' })
      axios.get(url).then(response => {
        dispatch({ type: 'success', payload: response.data })
      })
        .catch(error => {
          console.error('Error in useReq', error)
          dispatch({ type: 'error', payload: error })
        })
    }
  }, [url, count])

  return {
    ...state,
    setData: data => dispatch({ type: 'setData', payload: data }),
  }
}
