import React from 'react'
import Notes from './Notes'
import usePersistedSetting from '../hooks/usePersistedSetting'

export default function NotesContainer({
  notes,
  setNotes,
  selectNote,
  selectedNote,
  noteBeingLoaded,
  onAddNote,
  fuzzySearchTerm,
  setFuzzySearchTerm,
}) {
  const [sortType, setSortType] = usePersistedSetting('sortType', 'updatedAt')
  const [sortDir, setSortDir] = usePersistedSetting('sortDir', 'desc')
  
  return <Notes
    notes={notes}
    setNotes={setNotes}
    sortType={sortType}
    setSortType={setSortType}
    sortDir={sortDir}
    setSortDir={setSortDir}
    selectNote={selectNote}
    selectedNote={selectedNote}
    noteBeingLoaded={noteBeingLoaded}
    onAddNote={onAddNote}
    fuzzySearchTerm={fuzzySearchTerm}
    setFuzzySearchTerm={setFuzzySearchTerm}
  />
}
