import React from 'react'
import { loginUrl } from '../lib/auth'

export default function Welcome() {
  return <div>
    <h1>CodeNotes</h1>
    <div>
      <a href={loginUrl}>Log in</a>
    </div>
  </div>
}
