import React, { forwardRef } from 'react'
import { formatWithTime } from '../lib/date'
import './NoteListing.scss'
import spinnerImg from  '../images/spinner.gif'
import classnames from 'classnames'

function NoteListing({ note, onClick, isLoading, isSelected }, ref) {
  return <div
    ref={ref}
    className={classnames('note-listing', { selected: isSelected })}
    onClick={() => onClick(note)}
  >
    <div>
      <div className="title">{note.title}</div>
      <div className="date">{formatWithTime(new Date(note.updatedAt))}</div>
    </div>
    <div className="loading-indicator">
      {isLoading && <img alt="wait spinner indicator" src={spinnerImg} />}
    </div>
  </div>
}

export default forwardRef(NoteListing)
