import { useEffect, useRef, useState } from 'react'

export function useExpandHeight() {
  const el = useRef(null)
  const [height, setHeight] = useState(500)
  function onResize() {
    const h = window.innerHeight - el.current.getBoundingClientRect().top
    setHeight(h)
  }
  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  })

  return {
    height,
    el,
  }
}
