import { useReducer, useEffect } from 'react'

export default function useReq(promiseFn, shouldReq = true) {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'start':
        return { ...state, isRequesting: true }
      case 'success':
        return { ...state, isRequesting: false, isDone: true }
      case 'error':
        return { ...state, isRequesting: false, isDone: true, error: action.payload }
      default:
        return state
    }
  }, {
    isRequesting: shouldReq,
    error: null,
    data: undefined,
    isDone: false,
  })

  useEffect(() => {
    if (!shouldReq) {
      return
    }
    dispatch({ type: 'start' })
    promiseFn().then(response => {
      // We might not have a response if we have already chained a .then to the promise.
      // For convenience, if that promise doesn't supply a value, we don't try to use it.
      const effectiveResponse = response && response.data
      // Pause for a bit so we don't flash a 'You must login to access this area' message.
      setTimeout(() => dispatch({ type: 'success', payload: effectiveResponse }), 5)
    })
      .catch(error => {
        console.error('Error in useReq', error)
        dispatch({ type: 'error', payload: error })
      })
  }, [])

  return state
}
