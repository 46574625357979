import React from 'react'
import useFetch from '../hooks/useFetch'

export default function Loader(props) {
  const { isRequesting, error, isDone, data, setData } = useFetch(props.url)

  if (isRequesting) {
    return <div>Loading...</div>
  } else if (error) {
    return <div>Error in Loader</div>
  } else if (isDone) {
    return props.children({ data, setData })
  } else {
    // This is only show for the briefest moment during transitions with isRequesting.
    return <div></div>
  }
}
