import React, { useContext } from 'react'
import GlobalContext from './GlobalContext'
import useReq from '../hooks/useReq'
import { Link } from 'react-router-dom'
import axios from 'axios'

export default function Logout() {
  const { user, logout } = useContext(GlobalContext)

  const { isRequesting, error, data } = useReq(() =>
    axios.delete('/api/me')
      .then(logout)
  , !!user)
  
  if (isRequesting) {
    return <div>Logging you out...</div>
  } else if (error) {
    return <div>An unexpected error occurred trying to log you out. Try again.</div>
  } else if (data) {
    return <div>
      <div>You have successfully logged out</div>
      <div><Link to="/">Click here to log in</Link></div>
    </div>
  } else if (!user) {
    return <div>You are not logged in. <Link to="/">Click here to log in</Link></div>
  } else {
    // This should only show for the briefest moment.
    return <div></div>
  }
}
