import React, { useState } from 'react'
import { Route, Redirect, Link, Switch } from 'react-router-dom'
import axios from 'axios'
import update from 'immutability-helper'
import Welcome from './Welcome'
import DashboardContainer from './DashboardContainer'
import SearchContainer from './SearchContainer'
import Header from './Header'
import About from './About'
import Logout from './Logout'
import GlobalContext from './GlobalContext'
import { loginUrl } from '../lib/auth'
import useReq from '../hooks/useReq'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

export default function App() {
  const [globalState, setGlobalState] = useState({
    user: null,
    logout,
    toast,
  })
  const { user } = globalState
  function logout() {
    setGlobalState(g => update(g, { user: { $set: null } }))
  }
  const { isRequesting, error } = useReq(() => axios.get('/api/me')
    .then(response => {
      setGlobalState(g => update(g, { user: { $set: response.data } }))
    })
  )
  function waitForLogin(WrappedComponent) {
    return () => {
      if (error) {
        return <div>We experienced an error. Please refresh the page.</div>
      } else if (isRequesting) {
        return <div>Loading...</div>
      } else {
        return <WrappedComponent />
      }
    }
  }
  
  return <GlobalContext.Provider value={globalState}>
    <Switch>
      <Route path="/" exact render={() => {
        if (user) {
          return <Redirect to="/dashboard" />
        }
        return <Welcome />
      }} />
      <Route path="/pages/about" component={About} />
      <Route path="/dashboard" render={waitForLogin(() => {
        if (user) {
          return <>
            <Header />
            <Switch>
              <Route path="/dashboard/search" component={SearchContainer} />
              <Route path="/dashboard" component={DashboardContainer} />
            </Switch>
          </>
        } else {
          return <div>You are trying to enter a protected area. <a href={loginUrl}>Login here</a></div>
        }
      })} />
      <Route path="/logout" component={waitForLogin(Logout)} />
      <Route path="">
        <h1>404 Bad URL</h1>
        <Link to="/">Return to the home page</Link>
      </Route>
    </Switch>
    <ToastContainer />
  </GlobalContext.Provider>
}
