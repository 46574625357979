import React from 'react'
import NotesList from './NotesList'
import AddNoteContainer from './AddNoteContainer'
import SortAndSearch from './SortAndSearch'
import FuzzySearchContainer from './FuzzySearchContainer'
import './Notes.scss'

export default function NotesContainer({
  notes,
  setNotes,
  selectNote,
  selectedNote,
  noteBeingLoaded,
  onAddNote,
  sortType,
  setSortType,
  sortDir,
  setSortDir,
  fuzzySearchTerm,
  setFuzzySearchTerm,
}) {
  return <div className="notes">
    <FuzzySearchContainer
      notes={notes}
      selectNote={selectNote}
      fuzzySearchTerm={fuzzySearchTerm}
      setFuzzySearchTerm={setFuzzySearchTerm}
    />
    <AddNoteContainer onAddNote={onAddNote} />
    <SortAndSearch
      sortType={sortType}
      setSortType={setSortType}
      sortDir={sortDir}
      setSortDir={setSortDir}
    />
    <NotesList
      notes={notes}
      selectNote={selectNote}
      selectedNote={selectedNote}
      noteBeingLoaded={noteBeingLoaded}
      sortType={sortType}
      sortDir={sortDir}
    />
  </div>
}
