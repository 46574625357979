import { useEffect } from 'react'
import Mousetrap from 'mousetrap'

export default function useKeyEvent(keyboardShortcut, callback, dependencyList) {
  useEffect(() => {
    Mousetrap.bind(keyboardShortcut, callback)

    return () => {
      Mousetrap.unbind(keyboardShortcut)
    }
  }, dependencyList)
}
