import React, { useState } from 'react'
import SearchInputs from './SearchInputs'
import SearchResults from './SearchResults'
import LoadingSpinner from './LoadingSpinner'
import ErrorComponent from './ErrorComponent'
import Loader from './Loader'
import useFetch from '../hooks/useFetch'
import NoteEditorContainer from './NoteEditorContainer';
import update from 'immutability-helper'
import './SearchContainer.scss'

export default function SearchContainer() {
  const [searchTerms, setSearchTerms] = useState('')
  const [searchUrl, setSearchUrl] = useState('')
  const [fetchCount, setFetchCount] = useState(0)
  const [noteIdToEdit, setNoteIdToEdit] = useState(null)
  
  function search() {
    setFetchCount(fetchCount + 1)
    setSearchUrl('/api/notes/search?q=' + searchTerms)
    setNoteIdToEdit(null)
  }

  function onSelectNoteIdToEdit(id) {
    setNoteIdToEdit(id)
  }

  const { isRequesting, error, data : searchResults, setData : setSearchResults } = useFetch(searchUrl, fetchCount)
  function updateSearchResult(searchResultNote) {
    const updatedSearchResults = update(searchResults, {
      hits: {
        [searchResults.hits.findIndex(x => x.id === searchResultNote.id)]: {
          source: { $set: searchResultNote }
        },
      }
    })
    setSearchResults(updatedSearchResults)
  }
  
  return <div>
    <h1>Search</h1>
    <SearchInputs
      searchTerms={searchTerms}
      onSetSearchTerms={setSearchTerms}
      onSearch={search}
    />
    {isRequesting
      ? <LoadingSpinner />
      : error
        ? <ErrorComponent message="Error loading search results" />
        : searchResults && (function showResultsOrResultsAndEditor() {
            const ConfiguredSearchResults = <SearchResults
              onSelectNoteIdToEdit={onSelectNoteIdToEdit}
              noteIdToEdit={noteIdToEdit}
              results={searchResults}
            />
            if (noteIdToEdit) {
              return <div className="results-with-editor">
                <div className="search-results-wrapper">
                  {ConfiguredSearchResults}
                </div>
                <div className="note-editor-wrapper">
                  <Loader url={`/api/notes/${noteIdToEdit}`}>
                    {({ data: note, setData }) => <NoteEditorContainer
                      note={note}
                      updateNote={n => {
                        setData(n)
                        updateSearchResult(n)
                      }}
                    />}
                  </Loader>
                </div>
                <button className="close-button" onClick={() => onSelectNoteIdToEdit(null)}>Close</button>
              </div>
            }
            return ConfiguredSearchResults
          })()
    }
  </div>
}
