import React, { useEffect, useRef } from 'react'
import './NotesList.scss'
import NoteListing from './NoteListing'
import { useExpandHeight } from '../hooks/useExpandHeight'
import { naturalSorter } from '../lib/sort'

export default function NotesList({
  notes,
  selectNote,
  selectedNote,
  noteBeingLoaded,
  sortType,
  sortDir,
}) {
  if (['updatedAt', 'title', 'viewedAt'].includes(sortType)) {
    const sortedNotes = naturalSorter(notes, x => {
      if (sortType === 'title') {
        return x[sortType].toLowerCase()
      }
      return x[sortType]
    }, { reverse: sortDir === 'desc' })
    notes = sortedNotes
  }

  const { el, height } = useExpandHeight()

  // When a note is selected, ensure it's visible with scrollIntoView.
  // To figure out how to use an array of refs, I used: https://stackoverflow.com/a/56063129/135101
  const notesRef = useRef([])
  useEffect(() => {
    if (selectedNote) {
      const index = notes.findIndex(x => x.id === selectedNote.id)
      // This used to work without setTimeout. I had a hunch that a setTimeout would help it, and since it does,
      // I don't feel like looking into it further.
      setTimeout(() => {
        notesRef.current[index].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      }, 0)
    }
  }, [notes, selectedNote])
  useEffect(() => {
    notesRef.current = notesRef.current.slice(0, notes.length)
  }, [notes])

  return <div className="notes-list" style={{ height: height + 'px' }} ref={el}>
    {notes.length
      ? notes.map((note, i) => <NoteListing
          key={note.id}
          ref={el => notesRef.current[i] = el}
          note={note}
          onClick={selectNote}
          isSelected={selectedNote && selectedNote.id === note.id}
          isLoading={noteBeingLoaded && noteBeingLoaded.id === note.id}
        />)
      : <div className="empty-list">
          No notes. Use the 'Add note' button above.
        </div>
    }
  </div>
}
